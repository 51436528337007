import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import ExploreContainer from '../components/ExploreContainer';
import CustomHeader from '../components/header/CustomHeader';
import MenuHeader from '../components/header/Menu';
import AttendanceReportComponent from '../components/report/AttendanceReportComponent';
import './Tab2.css';

const ReportAttendanceTab = () => {
  const headerInit={
    title:'Attendance Report'
  }
  return (
    <IonPage>
      <IonContent fullscreen>
        <CustomHeader></CustomHeader>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tab 2</IonTitle>
          </IonToolbar>
        </IonHeader>
        <AttendanceReportComponent />
      </IonContent>
    </IonPage>
  );
};

export default ReportAttendanceTab;

import { IonLoading } from "@ionic/react";
import { useRef, useState } from "react";

const LoadingIon=(props)=>{
    const ref=useRef(null);
    const close=()=>{
        console.log("log close");
    }
    return(
        <IonLoading
            isOpen={props.modal.isOpen}
            ref={ref}
            cssClass="my-custom-class"
            message={props.modal.message}
        >
        </IonLoading>
    )
}
export default LoadingIon;
import { IonAvatar, IonCard, IonCardContent, IonCol, IonContent, IonHeader, IonLabel, IonPage, IonRow, IonSlide, IonSlides, IonTitle, IonToolbar } from "@ionic/react"
import SupportComponent from "../components/company/SupportComponent";
import CustomHeader from "../components/header/CustomHeader";

const SupportPage=()=>{
    const slideOpts = {
        initialSlide: 1,
        speed: 400
    };
    return(
        <IonPage>
            <IonContent fullscreen>
                <CustomHeader></CustomHeader>
                <IonHeader collapse="condense">
                    <IonToolbar>
                        <IonTitle size="large">Support</IonTitle>
                    </IonToolbar>
                </IonHeader>
                <SupportComponent></SupportComponent>
            </IonContent>
        </IonPage>
        
    )
}

export default SupportPage;
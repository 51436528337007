import { IonButton, IonCard, IonCardContent, IonCol, IonRow } from "@ionic/react"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
const AboutComponent=()=>{
    const initialState={
        loading:{
            isOpen:false,
            message:'Loading...',
        },
        appDomain:'',
        token:'',
    }
    const [appDomain,setAppDomain]=useState(initialState.appDomain);
    const [appToken,setAppToken]=useState(initialState.token);
    const dispatch=useDispatch();
    const auth=useSelector(state => state.auth);
    useEffect(() => {
        setAppDomain(auth.baseUrl);
        setAppToken(auth.data.token);
    },[auth.data]);

    return(
        <IonRow>
            <IonCol size-sm="12" size-xs="12">
                
                {
                    appDomain != '' && appToken != '' ? (
                        <iframe
                            src={`${appDomain}/admin/login-url?token=${appToken}`}
                            style={{width:'100%',height:'100vh'}}
                            scrolling="yes" ></iframe>
                    ) : <h1>Loading..</h1>
                }
            </IonCol>
            {/* <IonCol size-sm="4" size-xs="6">
                <IonCard color="danger">
                    <IonCardContent>
                        Test
                    </IonCardContent>
                </IonCard>
            </IonCol> */}
        </IonRow>
    )
}
export default AboutComponent;
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonDatetime, IonDatetimeButton, IonFab, IonFabButton, IonGrid, IonIcon, IonLabel, IonModal, IonRow, IonTitle, useIonAlert } from "@ionic/react";
import { useEffect, useState } from "react";
import LoadingIon from "../shared/LoadingIon";
import ModalBottomSheet from "../shared/ModalBottomSheet";
import '../shared/css/modalCss.css';
import { callGet } from "../../composables/Api";
import ReportTable from "./ReportTable";
import { filter as icon_filter } from 'ionicons/icons';
const AttendanceReportComponent=()=>{

    const [presentAlert] = useIonAlert();
    const handleModal=(evt,status)=>{
        console.log("close");
        setModalInfo({
            ...modalInfo,
            is_open:status !== undefined ? status: !modalInfo.is_open
        });
        // setLoading({
        //     ...loading,
        //     isOpen:true,
        //     message:'Loading...', 
        // });
        // setTimeout(() => {
        //     setLoading({
        //         ...loading,
        //         isOpen:false,
        //         message:'Loading...', 
        //     });
        // }, 2000);
    }
    const initialState={
        loading:{
            isOpen:false,
            message:'Loading...',
        },
        modalInfo:{
            is_open:false,
        },
        apiUrl:'/api/get-employee-attendance',
        filter_data:{
            start_date:'',
            end_date:'',
        }
    }
    const [loading,setLoading]=useState(initialState.loading);
    const [modalInfo,setModalInfo]=useState(initialState.modalInfo);
    const [data,setData]=useState([]);
    const [rawData,setRawData]=useState([]);
    const [filter,setFilter]=useState(initialState.filter_data);
    const [apiUrl,setApiUrl]=useState(initialState.apiUrl);

    useEffect(() => {
        get();
    },[]);

    const handleDate=(class_name,date)=>{
        //console.log("date=",date);
        setFilter({
            ...filter,
            [class_name]:date
        });
    }
    const handleFilter=async()=>{
        console.log("date=",filter);
        await get(undefined,1);   
        handleModal();
    }
    const get=async(url,isFilter)=>{
        setLoading({...loading,isOpen:true});
        console.log("get=",url);
        let status=0;
        if(url !== undefined)
          status=1;
      
        const api=await callGet(url !== undefined ? url: apiUrl,filter,status);
        console.log("api=",api);
        if(api && api.error == undefined){
          //pageInfo.rawData=api.data;
          setRawData(api.data);
          if(isFilter == undefined){
            const list=api.data.data.length > 0 ? [...data].concat(api.data.data) : data;
            setData(list);
          }  
          else
            setData(api.data.data);
          //console.log("api=",pageInfo.list);
        }
        else
            {
                presentAlert({
                    header: 'Alert',
                    subHeader: 'Warning',
                    message: api.error,
                    buttons: ['OK'],
                });
            }
        setLoading({...loading,isOpen:false});
    }
    const ionInfinite = async(ev) => {
        console.log("next page=",rawData.next_page_url);
        if(rawData && rawData.next_page_url){
            //setApiUrl(rawData.next_page_url);
            await get(rawData.next_page_url);
            console.log("env=",ev.target);
            ev.target.complete();
        }
        else{
            //console.log("env=",ev.target);
            ev.target.complete();
        }
    };
    return(
        <>
            <IonFab style={{marginBottom:'5%'}} vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton>
                    <IonIcon onClick={() =>handleModal(true)} icon={icon_filter}></IonIcon>
                </IonFabButton>
            </IonFab>
            <div>
                <ReportTable handleModal={handleModal} ionInfinite={ionInfinite} list={data}></ReportTable>   
                <ModalBottomSheet close={handleModal} modal_info={modalInfo}>
                    <div className="modalHeader justify-content-between">
                        <IonButtons slot="start">
                            <IonButton onClick={handleModal}>Cancel</IonButton>
                        </IonButtons>
                        {/* <IonTitle>Filter With Date</IonTitle> */}
                        <div className="">
                            <span style={{textAlign:'center'}}>
                                <b>Filter With Date</b>
                            </span>
                        </div>
                        <div>&nbsp;</div>
                    </div>
                    <div>
                        <IonGrid>
                            <IonRow>
                                <IonCol size-sm="8" offset-sm="2" size-xs="12">
                                    <IonCard style={{width:'100%'}}>
                                        <IonCardHeader>
                                            <IonCardTitle>Filter With Date</IonCardTitle>
                                        </IonCardHeader>
                                        <IonCardContent>
                                            <div style={{display:'flex'}}>
                                                <div>
                                                    <div style={{textAlign:'left'}}>
                                                    <IonLabel>Start Date</IonLabel>
                                                    </div>
                                                    <div style={{display:'flex'}}>
                                                        <IonDatetimeButton                                   
                                                            showDefaultButtons={true}
                                                            datetime="start_datetime"></IonDatetimeButton>
                                                    </div>
                                                </div>
                                                <div>
                                                    <div style={{textAlign:'left'}}>
                                                    <IonLabel>End Date</IonLabel>
                                                    </div>
                                                    <div style={{display:'flex'}}>
                                                        <IonDatetimeButton                                   
                                                            showDefaultButtons={true}
                                                            datetime="end_datetime"></IonDatetimeButton>
                                                    </div>
                                                </div>
                                                <div>
                                                    <IonLabel>&nbsp;</IonLabel>
                                                    <div style={{display:'flex'}}>
                                                    <IonButton onClick={handleFilter} size="small" style={{margin:0}}>
                                                        <IonIcon slot="start" icon="searchCircle"></IonIcon>
                                                        Submit
                                                    </IonButton>
                                                    </div>
                                                </div>
                                            </div>                              
                                        </IonCardContent>
                                    </IonCard>  
                                </IonCol>
                            </IonRow>
                            <IonModal 
                                keepContentsMounted={true}>
                                <IonDatetime 
                                    presentation="date" 
                                    showDefaultButtons
                                    onIonChange={e => handleDate('start_date',new Date(e.detail.value))} 
                                    id="start_datetime" 
                                />
                            </IonModal>
                            <IonModal 
                                keepContentsMounted={true}>
                                <IonDatetime 
                                    presentation="date" 
                                    showDefaultButtons
                                    onIonChange={e => handleDate('end_date',new Date(e.detail.value))} 
                                    id="end_datetime" 
                                />
                            </IonModal>
                        </IonGrid>
                    </div>
                </ModalBottomSheet>
                <LoadingIon modal={loading} />
            </div>
        </>
    )
}
export default AttendanceReportComponent;
import { 
    IonCard, 
    IonCardContent, 
    IonCardHeader, 
    IonCardTitle, 
    IonFab, 
    IonFabButton, 
    IonIcon, 
    IonInfiniteScroll, 
    IonInfiniteScrollContent, 
    IonItem, 
    IonLabel, 
    IonList, 
    IonRow
 } from "@ionic/react";
import './report.css';
import { filter } from 'ionicons/icons';
const ReportTable=({list,ionInfinite,handleModal})=>{

    const ContentItem=list.map((item,index) => {
        return(
            <IonItem  key={item.id}>
                <IonLabel 
                    className="td_border mn item_attendance">
                         {(index + 1)}
                </IonLabel>
                <IonLabel
                    text-nowrap 
                    className="td_border mn item_attendance">
                        {item.date}
                </IonLabel>
                <IonLabel
                    text-nowrap 
                    className="td_border mn item_attendance">
                        {item.time}
                </IonLabel>
            </IonItem>
        )
    })
    
    return(
            <>
                {/* <IonFab style={{marginBottom:'10%'}} vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton>
                        <IonIcon onClick={() =>handleModal(true)} icon={filter}></IonIcon>
                    </IonFabButton>
                </IonFab> */}
                <IonRow>
                    <IonCard className="ion_item_card">
                        <IonCardHeader className="ion_item_card_header">
                            <IonCardTitle>Attendance List</IonCardTitle>
                        </IonCardHeader>
                        <IonCardContent className="ion_item_card_content">
                            <IonList>
                                <IonItem className="ion_item_head">
                                    <IonLabel className="td_border"> SL </IonLabel>
                                    <IonLabel className="td_border"> Date </IonLabel>
                                    <IonLabel className="td_border"> Time </IonLabel>
                                </IonItem>
                                {ContentItem}
                            </IonList>
                            <IonInfiniteScroll 
                                onIonInfinite={ionInfinite}>
                                <IonInfiniteScrollContent
                                    loading-text="Please wait..."
                                    loading-spinner="bubbles"
                                ></IonInfiniteScrollContent>
                            </IonInfiniteScroll>
                        </IonCardContent>
                    </IonCard>
                </IonRow>
            </>
    )
}
export default ReportTable;
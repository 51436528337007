import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar,IonButtons,IonMenuButton } from '@ionic/react';
import MenuHeader from '../components/header/Menu';
import DefLayout from '../components/layouts/DefaultLayout';
import HomeComponent from '../components/dashboard/HomeComponent';
import CustomHeader from '../components/header/CustomHeader';

const HomeTab= () => {

  //console.log("match=",match);

  const headerInit={
    title:'Home'
  }
  return (
    <IonPage>
      {/* <MenuHeader headers={headerInit} /> */}
      
      <IonContent fullscreen>
        <CustomHeader></CustomHeader>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Tab 1</IonTitle>
          </IonToolbar>
        </IonHeader>
        <DefLayout>
            <HomeComponent />
        </DefLayout>
      </IonContent>
    </IonPage>
  );
};

export default HomeTab;

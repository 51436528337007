import { IonContent, IonHeader, IonModal, IonToolbar } from "@ionic/react";
import React from "react";

const ModalBottomSheet=(props)=>{
    const onWillDismiss=()=>{
        props.close(null,false);
        console.log("close from modal");
    }
    const { children } = props;
    const content=React.Children.toArray(children);
    console.log("content=",content);
    //const divChildren = content[0].filter(child => child.type === 'div');
    return(
        <IonModal 
            isOpen={props.modal_info.is_open}
            onWillDismiss={onWillDismiss}>
            <IonHeader>
                <IonToolbar>
                    {content[0]}
                </IonToolbar>
            </IonHeader>
            <IonContent class="ion-padding">
                {content[1]}
            </IonContent>
        </IonModal>
    )
}
export default ModalBottomSheet;
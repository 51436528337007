import { useSelector } from 'react-redux';
import StorageIonic from '../plugins/StorageIonic';
import { APP_CONFIG_URL, INIT_ACTION, LOGIN_ACTION, LOGOUT_ACTION } from './types';
export const appConfigAction = (payload) => (dispatch) => {
  setAuthData(payload);
  dispatch({
    type: APP_CONFIG_URL,
    payload:payload.baseUrl,
    localServer:payload.localServer,
  });
};
export const loginAction = (payload) => (dispatch) => {
  dispatch({
    type: LOGIN_ACTION,
    payload,
  });
};
export const logOutAction = (payload) => async(dispatch) => {
  const auth=await getAuthData();
  //auth.data=null;
  auth.isAuthenticate=false;
  auth.baseUrl=auth.baseUrl;
  console.log("logout=",auth);
  await setAuthData(auth);
  dispatch({
    type: LOGOUT_ACTION,
    payload,
  });
};
export const initAction=(payload)=>async(dispatch)=>{
  const data=await getAuthData();
  dispatch({
    type: INIT_ACTION,
    payload:data,
  });
}
export const getAuthData=async()=>{
    const auth=await (await StorageIonic.init()).auth();
    return auth;
}
export const setAuthData=async(data)=>{
    const auth=await (await StorageIonic.init()).setAuth(data);
    return getAuthData();
}
export const removeAuth=async()=>{
    await (await StorageIonic.init()).removeKey();
    return 1;
}

import { useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { Route, Redirect } from 'react-router-dom';
import { getAuthData } from '../../actions/authActions';

const PrivateRoute = ({ component: Component, ...rest }) => {

const  [isAuthenticated,setIsAuthenticated]  =  useState(2);
const auth=useSelector(state => state.auth);
 useEffect(() => {
    console.log("private=",auth);
  },[auth])  
  useEffect(() => {
    
    //setIsAuthenticated(auth.isAuthenticate);
    getAuthData().then(res => {
        //console.log("res data=",res.isAuthenticate);
        if(res)
            setIsAuthenticated(res.isAuthenticate);
        else
            setIsAuthenticated(false);
    })
  },[auth])
  
  return (
        <Route
            {...rest}
            render={(props) => isAuthenticated && isAuthenticated != 2 ? (<Component {...props} />) : (isAuthenticated == 2 ? <h1 className='text-center'>Loading...</h1> : <Redirect to="/login" />)}
        />
    );
}
export default PrivateRoute;

import { Device } from "@capacitor/device";
import { IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCol, IonContent, IonHeader, IonInput, IonLabel, IonPage, IonRow, IonTitle, IonToolbar, useIonRouter } from "@ionic/react";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { appConfigAction } from "../../actions/authActions";
import LoadingIon from "../../components/shared/LoadingIon";

const ConfigPage=()=>{
    const initialState={
        loading:{
            isOpen:false,
            message:'Loading...',
        },
        appDomain:'',
        localServer:'',
    }
    const ionRouter = useIonRouter();
    const dispatch=useDispatch();
    const auth=useSelector(state => state.auth);
    const [loading,setLoading]=useState(initialState.loading);
    const [appDomain,setAppDomain]=useState(initialState.appDomain);
    const [localServer,setLocalServer]=useState(initialState.localServer);
    
    useEffect(() => {
        setAppDomain(auth.baseUrl);
        setLocalServer(auth.localServer);
    },[auth.baseUrl]);
    const handleBaseApp=async()=>{
        setLoading({...loading,isOpen:true});
        auth.baseUrl=appDomain;
        auth.localServer=localServer;
        await dispatch(appConfigAction(auth));
        setLoading({...loading,isOpen:false});
        ionRouter.push('/login');
    }
    const [headerMobile,setHeaderMobile]=useState(['header']);
    useEffect(() => {
        logBatteryInfo();
    },[]);
    const logBatteryInfo = async () => {
        const info = await Device.getInfo();
        if(info.platform != 'web'){
            setHeaderMobile([headerMobile].concat('header_mobile'));
        }
    };
    return(
        <IonPage>
            <IonHeader>
                <IonToolbar color="danger" className={headerMobile.join(' ')}>
                    <IonTitle>Config</IonTitle>
                    <IonButtons slot="primary">
                        <IonButton routerLink="/login">Go to login</IonButton>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonContent fullscreen="true">
                <IonCard>
                    <IonCardHeader>
                        <IonCardTitle>Company Setting</IonCardTitle>
                    </IonCardHeader>
                    <IonCardContent>
                        <IonRow>
                            <IonCol size-sm="4" offset-sm="4" size-xs="12">
                                <div>
                                    <IonLabel position="stacked">
                                        App Domain
                                    </IonLabel>
                                    <IonInput 
                                        className="custom"
                                        value={appDomain}
                                        onIonChange={e => setAppDomain(e.detail.value)} 
                                        placeholder="Enter valid domain"></IonInput>
                                </div>
                                <div>
                                    <IonLabel position="stacked">
                                        Local Server
                                    </IonLabel>
                                    <IonInput 
                                        className="custom"
                                        value={localServer}
                                        onIonChange={e => setLocalServer(e.detail.value)} 
                                        placeholder="Local Server"></IonInput>
                                </div>
                                <div>
                                    <IonButton onClick={handleBaseApp} color="primary">Save</IonButton>
                                </div>
                            </IonCol>
                        </IonRow>
                    </IonCardContent>
                </IonCard>
                <LoadingIon modal={loading} />
            </IonContent>
        </IonPage>
    )
}
export default ConfigPage;
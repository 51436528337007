import { IonBadge, IonButton, IonButtons, IonIcon, IonSegment, IonSegmentButton, IonTitle, IonToolbar, useIonAlert, useIonRouter } from "@ionic/react";
import { notifications,people,person } from 'ionicons/icons';
import { useState } from "react";
import { useDispatch } from "react-redux";
import { logOutAction } from "../../actions/authActions";
import LoadingIon from "../shared/LoadingIon";
import { BarcodeScanner } from '@capacitor-community/barcode-scanner';

const HeaderSegment=()=>{
    const dispatch=useDispatch();
    const [presentAlert] = useIonAlert();
    const ionRouter = useIonRouter();
    const initialState={
        loading:{
            isOpen:false,
            message:'Loading...',
        },
    }
    const [loading,setLoading]=useState(initialState.loading);
    const handleLogout=async()=>{
        setLoading({...loading,isOpen:true});
        await dispatch(await logOutAction());
        setLoading({...loading,isOpen:false});
        ionRouter.push('/login');  
        setTimeout(() => {
            window.location.reload();
        }, 200);
    }
    const openScanner = async () => {
        await BarcodeScanner.checkPermission({ force: true });
        // make background of WebView transparent
        // note: if you are using ionic this might not be enough, check below
        BarcodeScanner.hideBackground();
        const result = await BarcodeScanner.startScan(); // start scanning and wait for a result
        // if the result has content
        if (result.hasContent) {
            presentAlert({
                header: 'Alert',
                subHeader: 'Success',
                message: result.content,
                buttons: ['OK'],
            });
            //console.log(result.content); // log the raw scanned content
        }
    };

    return(
        <>
            <IonToolbar class="header_toolbar">
                <IonButtons slot="primary">
                    <IonButton onClick={openScanner} fill="clear" className="" size="small">
                        <IonIcon  slot="icon-only"  icon={notifications}></IonIcon>
                        <IonBadge className="badge" color="">0</IonBadge>
                    </IonButton>  
                    <IonButton onClick={handleLogout}>
                        <IonIcon   size="small" slot="icon-only" icon={person}></IonIcon>
                    </IonButton>
                </IonButtons>
            </IonToolbar>
            <LoadingIon modal={loading} />
      </>
    )
}
export default HeaderSegment;
import { getAuthData } from "../actions/authActions";
import { APP_CONFIG_URL, INIT_ACTION, LOGIN_ACTION, LOGOUT_ACTION } from "../actions/types";
import isAuthenticate from "../components/auth/IsAuthenticate";

const initialState = {
    isAuthenticate: false,
    data:null,
    baseUrl:null,
    localServer:null,
};

export const authInital=initialState;

// const auth=await getAuthData();
// if(auth){
//     initialState.isAuthenticate=true;
//     initialState.data=auth;
// }

// getAuthData().then(res => {
//     if(res){
//         initialState.isAuthenticate=true;
//         initialState.data=res;
//     }
// });

const counterReducer=(state = initialState, action)=>{
    switch (action.type) {
        case LOGIN_ACTION:
            //console.log("action auth=",action);
            return { ...state, isAuthenticate:true,data:action.payload };
        case INIT_ACTION:
            return { ...state, ...action.payload };
        case APP_CONFIG_URL:
            return { 
                ...state, 
                baseUrl:action.payload,
                localServer:action.localServer ?? '',
            };
        case LOGOUT_ACTION:
            initialState.baseUrl=state.baseUrl;
            return initialState;
        default:
            return state;
    }
}

export default counterReducer;
import { IonAvatar, IonBadge, IonButton, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonGrid, IonIcon, IonItem, IonLabel, IonRow, IonText } from "@ionic/react";
import './header.css';
import { notifications,people } from 'ionicons/icons';
import HeaderSegment from "./HeaderSegment";
import { useSelector } from "react-redux";
import { Device } from '@capacitor/device';
import { useEffect, useState } from "react";

const CustomHeader=()=>{
    
    const auth=useSelector(state => state.auth);
    const [headerMobile,setHeaderMobile]=useState(['header']);
    useEffect(() => {
        logBatteryInfo();
    },[]);

    const logBatteryInfo = async () => {
        const info = await Device.getInfo();
        if(info.platform != 'web'){
            setHeaderMobile([headerMobile].concat('header_mobile'));
        }
    };
    return(
        <div className={headerMobile.join(' ')}>
            <IonGrid>
                <IonRow>
                    <IonCol size-sm="8" size-xs="7">
                        <IonRow>
                            <IonCol size-sm="4" size-xs="4">
                                <IonAvatar>                    
                                    <img 
                                        alt="Silhouette of a person's head" 
                                        src="/profile-ava.svg" />
                                </IonAvatar>
                            </IonCol>
                            <IonCol size-sm="8" size-xs="8">
                                <IonLabel >
                                    <div className="header_title">
                                        {auth && auth.data ? auth.data.active_user.name:''}
                                    </div>
                                    <div className="header_cmp_title">
                                        {auth && auth.data && auth.data.warehouses ? auth.data.warehouses[0]['name_eng']:''}
                                    </div>
                                </IonLabel>
                            </IonCol>
                        </IonRow>
                        
                    </IonCol>
                    <IonCol size-sm="4" size-xs="5" className="header_right_content">
                        <HeaderSegment></HeaderSegment>
                        {/* <IonButton fill="clear" className="" size="small">
                            <IonIcon size="small" slot="start" icon={notifications}></IonIcon>
                            <IonBadge className="badge" color="danger">2</IonBadge>
                        </IonButton>    
                        <IonIcon  size="small" className="" aria-hidden="true" icon={people} /> */}
                                                
                    </IonCol>
                </IonRow>
            </IonGrid>
        </div>
        // <IonCard>
        //     <IonCardHeader>
        //         <IonCardTitle>Card Title</IonCardTitle>
        //         <IonCardSubtitle>Card Subtitle</IonCardSubtitle>
        //     </IonCardHeader>
        //     <IonCardContent>
        //         Here's a small text description for the card content. Nothing more, nothing less.
        //     </IonCardContent>
        // </IonCard>
    )
}
export default CustomHeader;
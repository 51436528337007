import { IonContent, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react';
import AboutComponent from '../components/company/AboutComponent';
import ExploreContainer from '../components/ExploreContainer';
import CustomHeader from '../components/header/CustomHeader';
import DefLayout from '../components/layouts/DefaultLayout';
import './Tab3.css';
const Tab3 = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <CustomHeader></CustomHeader>
        <IonHeader collapse="condense">
          <IonToolbar>
            <IonTitle size="large">Function</IonTitle>
          </IonToolbar>
        </IonHeader>
        <DefLayout>
            <AboutComponent />
        </DefLayout>
      </IonContent>
    </IonPage>
  );
};

export default Tab3;

import { 
        IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, 
        IonCardHeader, IonCardTitle, IonCol, IonDatetime, IonDatetimeButton, 
        IonFab, IonFabButton, IonGrid, IonIcon, IonLabel, IonModal, IonRow, 
        IonSelect, IonSelectOption, useIonAlert, useIonLoading, useIonRouter } from "@ionic/react";
import axios from "axios";
import { filter as icon_filter } from 'ionicons/icons';
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { callGet, callPost } from "../../composables/Api";
import ModalBottomSheet from "../shared/ModalBottomSheet";


const DeviceDashboard=()=>{
    const initialState={
        loading:{
            isOpen:false,
            message:'Loading...',
        },
        modalInfo:{
            is_open:false,
        },
        apiUrl:'/api/get-employee-attendance',
        filter_data:{
            start_date:'',
            end_date:'',
            devices:[]
        }
    }
    const [presentAlert] = useIonAlert();
    const [loading,setLoading]=useState(initialState.loading);
    const [modalInfo,setModalInfo]=useState(initialState.modalInfo);
    const [filter,setFilter]=useState(initialState.filter_data);
    const [devices,setDevices]=useState([]);
    const auth=useSelector(state => state.auth);
    const ionRouter = useIonRouter();
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [present, dismiss] = useIonLoading();
    let isApiCall=null;
    useEffect(() => {
        if(!isApiCall)
            {
                isApiCall=1;
                get();
            }
    },[]);
    const get=async()=>{
        //setLoading(true);
        // let url='http://192.168.0.113:5000/get-users';
        // let api=await callGet(url,{},1);
        // if(api && api.error == undefined){
        //   setUsers(api);
        // }
        //let url='https://office.cursorltd.com/api/getDeviceInfo?wids=0&forceReq=1';
        let api=await callGet('/api/getDeviceInfo?wids=0&forceReq=1',{},0);
        console.log('error call=',api);
        if(api && api.error == undefined){
            setDevices(api);
            // let urlAtt='http://192.168.0.113:5000/get-device-attendance';
            // let apiAtt=await callPost(urlAtt,api,1);
            // if(apiAtt && apiAtt.error == undefined){
            //     setAttenadnces(apiAtt);
            //     setLoading(false);
            // }
        }
    }
    const handleModal=(evt,status)=>{
        setModalInfo({
            ...modalInfo,
            is_open:status !== undefined ? status: !modalInfo.is_open
        });
    }
    const handleFilter=async()=>{
        //await get(undefined,1);   
        //handleModal();
        console.log('local serve =',auth.localServer);

        if(!auth.localServer){
            presentAlert({
                header: 'Alert',
                subHeader: 'Warning',
                message: "Local server ip is required",
                buttons: ['OK'],
            });
            return;
        }

        if(filter.devices.length <= 0)
            return;

        try {
            // Array of API call promises
            //http://192.168.0.123:5000
            const promises = filter.devices.map((endpoint) => {
                filter.id=endpoint.ip;
                return axios.post(auth.localServer+"/attendance-sync", {data:filter})
                    .then(async(response) => {
                        //console.log(response.data);
                        const newlist=filter.devices.map(device=>({
                            ...device,
                            data:endpoint.ip == device.ip ? response.data.data : [],
                            errors:null,
                        }))
                        setFilter({
                            ...filter,
                            devices:newlist
                        })
                        const reqData={
                            ip_address:endpoint.ip,
                            data:(response.data.data)
                        };
                        const res=await callPost(`${auth.baseUrl}/api/uploadBulkAttedanceFromApp`,reqData,1);
                        return response.data;
                    })
                  .catch((e) => {
                        const newlist=filter.devices.map(device=>({
                            ...device,
                            data:endpoint.ip == device.ip ? [] : device.data,
                            errors:endpoint.ip == device.ip ? e : null
                        }))
                        setFilter({
                            ...filter,
                            devices:newlist
                        })
                        //console.log('e error=',e);
                  })
            });
            // Wait for all API calls to resolve
            present({
                message: 'Data fetching....',
                //duration: 3000,
            });
            const responses = await Promise.all(promises);
            dismiss();
            // Extract the data from the responses
            // const responseData = responses.map(response => response);
            // console.log("res=",responseData);

            // Set the data in the component state

          } catch (error) {
            //dismiss();
            console.log(error);
          }

    }
    const handleDate=(class_name,date)=>{
        //console.log("date=",date);
        setFilter({
            ...filter,
            [class_name]:date
        });
    }
    const handleDeviceInfo=(devices)=>{
        setFilter({
            ...filter,
            devices:devices
        });
    }

    const ContentDevices=devices.map((device) => (
        <IonSelectOption 
            key={device.id} 
            value={device}>
            {device.ip}
        </IonSelectOption>
    ));
    const ContentCard=devices.map(row =>{
        return(
            <IonCol key={row.ip} size-sm="4" size-xs="6">
                <IonCard>
                    <IonCardContent>
                        <div expand="block" className="support_card">
                                <IonAvatar>
                                    <img 
                                        alt="Silhouette of a person's head" 
                                        src="https://ionicframework.com/docs/img/demos/avatar.svg" />
                                </IonAvatar>
                                <h3 className="support_title">{row.ip}</h3>
                                <div>
                                    <IonButtons slot="primary">
                                        <IonButton color="primary">Sync</IonButton>
                                    </IonButtons>
                                </div>
                            </div>
                    </IonCardContent>
                </IonCard>
            </IonCol>
        )
    });

    return(
        <>
            <IonFab style={{marginBottom:'2%'}} vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton>
                    <IonIcon onClick={() =>handleModal(true)} icon={icon_filter}></IonIcon>
                </IonFabButton>
            </IonFab>
            <IonRow>{ContentCard}</IonRow>
            <ModalBottomSheet close={handleModal} modal_info={modalInfo}>
                <div className="modalHeader justify-content-between">
                    <IonButtons slot="start">
                        <IonButton onClick={handleModal}>Cancel</IonButton>
                    </IonButtons>
                    {/* <IonTitle>Filter With Date</IonTitle> */}
                    <div className="">
                        <span style={{textAlign:'center'}}>
                            <b>Filter With Date</b>
                        </span>
                    </div>
                    <div>&nbsp;</div>
                </div>
                <div>
                    <IonGrid>
                        <IonRow>
                            <IonCol size-sm="8" offset-sm="2" size-xs="12">
                                <IonCard style={{width:'100%'}}>
                                    <IonCardHeader>
                                        <IonCardTitle>Filter With Date</IonCardTitle>
                                    </IonCardHeader>
                                    <IonCardContent>
                                        <div style={{display:'flex'}}>
                                            <div>
                                                <div style={{textAlign:'left'}}>
                                                    <IonLabel>Devices</IonLabel>
                                                </div>
                                                <IonSelect
                                                    multiple={true}
                                                    placeholder="Select Device"
                                                    onIonChange={(ev) => handleDeviceInfo(ev.detail.value)} 
                                                    labelPlacement="floating" 
                                                    fill="outline">
                                                    {ContentDevices}
                                                </IonSelect>
                                            </div>                                     
                                        </div>
                                        <div style={{display:'flex'}}>
                                            <div>
                                                <div style={{textAlign:'left'}}>
                                                    <IonLabel>Start Date</IonLabel>
                                                </div>
                                                <div style={{display:'flex'}}>
                                                    <IonDatetimeButton                                   
                                                        showDefaultButtons={true}
                                                        datetime="start_datetime"></IonDatetimeButton>
                                                </div>
                                            </div>
                                            <div>
                                                <div style={{textAlign:'left'}}>
                                                <IonLabel>End Date</IonLabel>
                                                </div>
                                                <div style={{display:'flex'}}>
                                                    <IonDatetimeButton                                   
                                                        showDefaultButtons={true}
                                                        datetime="end_datetime"></IonDatetimeButton>
                                                </div>
                                            </div>
                                            <div>
                                                <IonLabel>&nbsp;</IonLabel>
                                                <div style={{display:'flex'}}>
                                                    <IonButton onClick={handleFilter} size="small" style={{margin:0}}>
                                                        <IonIcon slot="start" icon="searchCircle"></IonIcon>
                                                        Submit
                                                    </IonButton>
                                                </div>
                                            </div>
                                        </div>
                                        <div>
                                            <div>Device Data</div>
                                            <div>
                                                {
                                                    filter.devices ? (
                                                        filter.devices.map(device => {
                                                            const list=device.errors && device.errors  ? (device.errors.response ? device.errors.response.data : 'Server not found.') : device.data &&  Object.keys(device.data).map((key,value) => {
                                                                return (
                                                                    <>
                                                                        <p>User Id : {key}</p>
                                                                        <ul>{device.data[key].map(att => <li>{att.recordTime}</li>)}</ul>
                                                                    </>
                                                                )
                                                            });
                                                            return(
                                                                <>
                                                                    <h1>{device.ip}</h1>
                                                                    {/* <div dangerouslySetInnerHTML={{ __html: list }} /> */}
                                                                    <div>{list}</div>
                                                                </>
                                                            )
                                                        })
                                                    ) : null
                                                }
                                            </div>
                                        </div>                              
                                    </IonCardContent>
                                </IonCard>  
                            </IonCol>
                        </IonRow>
                        <IonModal 
                            keepContentsMounted={true}>
                            <IonDatetime 
                                presentation="date" 
                                showDefaultButtons
                                onIonChange={e => handleDate('start_date',new Date(e.detail.value))} 
                                id="start_datetime" 
                            />
                        </IonModal>
                        <IonModal 
                            keepContentsMounted={true}>
                            <IonDatetime 
                                presentation="date" 
                                showDefaultButtons
                                onIonChange={e => handleDate('end_date',new Date(e.detail.value))} 
                                id="end_datetime" 
                            />
                        </IonModal>
                    </IonGrid>
                </div>
            </ModalBottomSheet>
        </>
    )
}
export default DeviceDashboard;
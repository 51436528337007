import { Redirect,Route } from "react-router";
import PrivateRoute from "../components/auth/PrivateRoute";
import DeviceTab from "../pages/DeviceTab";
import HomeTab from "../pages/HomeTab";
import ReportAttendanceTab from "../pages/ReportAttendanceTab";
import SupportPage from "../pages/SupportPage";
import Tab3 from "../pages/Tab3";

const Routes=()=>{
    return(
        <>
            {/* <IonRouterOutlet>
                <Route exact path={match.url} component={UsersListPage} />
                <Route path={`${match.url}/users/:id`} component={UserDetailPage} />
            </IonRouterOutlet> */}
            {/* <Route path="/dashboard" render={props => <DashboardPage {...props} />} />
            <Route exact path="/" render={() => <Redirect to="/dashboard" />} /> */}
            <PrivateRoute exact path="/home" component={HomeTab} />
            <PrivateRoute exact path="/device" component={DeviceTab} />
            <PrivateRoute exact path="/report-attendance" component={ReportAttendanceTab} />
            <PrivateRoute exact path="/support" component={SupportPage} />
            <PrivateRoute exact path="/tab3" component={Tab3} />
            <Route exact path="/">
                <Redirect to="/home" />
            </Route>
        </>
    )
}
export default Routes;
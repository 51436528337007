import { Device } from '@capacitor/device';
import { IonInput, IonButton, IonItem, IonLabel, IonContent, IonHeader, IonPage, IonTitle, IonToolbar, useIonRouter, IonRow, IonCol, IonCard, IonCardHeader, IonCardTitle, IonCardContent, IonButtons, useIonAlert, IonSlides, IonSlide } from '@ionic/react';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router';
import { getAuthData, loginAction, setAuthData } from '../../actions/authActions';
import LoadingIon from '../../components/shared/LoadingIon';
import { callPost } from '../../composables/Api';
import { getDeviceInfo } from '../../composables/DeviceApi';
import { authInital } from '../../reducers/authReducer';

const LoginPage = () => {
  const initialState={
      loading:{
          isOpen:false,
          message:'Loading...',
      },
  }
  const [presentAlert] = useIonAlert();
  const [loading,setLoading]=useState(initialState.loading);
  const [login, setLogin] = useState({
    email:'',
    password:'',
  });
  const auth=useSelector(state => state.auth);
  const ionRouter = useIonRouter();
  const location = useLocation();
  const dispatch=useDispatch();
  const [headerMobile,setHeaderMobile]=useState(['header']);
  const slideOpts = {
    autoplay: {
      delay: 2000, // set delay time in milliseconds
    },
    on: {
      beforeInit() {
        const swiper = this;
        swiper.classNames.push(`${swiper.params.containerModifierClass}flip`);
        swiper.classNames.push(`${swiper.params.containerModifierClass}3d`);
        const overwriteParams = {
          slidesPerView: 1,
          slidesPerColumn: 1,
          slidesPerGroup: 1,
          watchSlidesProgress: true,
          spaceBetween: 0,
          virtualTranslate: true,
        };
        swiper.params = Object.assign(swiper.params, overwriteParams);
        swiper.originalParams = Object.assign(swiper.originalParams, overwriteParams);
      },
      setTranslate() {
        const swiper = this;
        const { $, slides, rtlTranslate: rtl } = swiper;
        for (let i = 0; i < slides.length; i += 1) {
          const $slideEl = slides.eq(i);
          let progress = $slideEl[0].progress;
          if (swiper.params.flipEffect.limitRotation) {
            progress = Math.max(Math.min($slideEl[0].progress, 1), -1);
          }
          const offset$$1 = $slideEl[0].swiperSlideOffset;
          const rotate = -180 * progress;
          let rotateY = rotate;
          let rotateX = 0;
          let tx = -offset$$1;
          let ty = 0;
          if (!swiper.isHorizontal()) {
            ty = tx;
            tx = 0;
            rotateX = -rotateY;
            rotateY = 0;
          } else if (rtl) {
            rotateY = -rotateY;
          }
  
           $slideEl[0].style.zIndex = -Math.abs(Math.round(progress)) + slides.length;
  
           if (swiper.params.flipEffect.slideShadows) {
            // Set shadows
            let shadowBefore = swiper.isHorizontal() ? $slideEl.find('.swiper-slide-shadow-left') : $slideEl.find('.swiper-slide-shadow-top');
            let shadowAfter = swiper.isHorizontal() ? $slideEl.find('.swiper-slide-shadow-right') : $slideEl.find('.swiper-slide-shadow-bottom');
            if (shadowBefore.length === 0) {
              shadowBefore = swiper.$(`<div class="swiper-slide-shadow-${swiper.isHorizontal() ? 'left' : 'top'}"></div>`);
              $slideEl.append(shadowBefore);
            }
            if (shadowAfter.length === 0) {
              shadowAfter = swiper.$(`<div class="swiper-slide-shadow-${swiper.isHorizontal() ? 'right' : 'bottom'}"></div>`);
              $slideEl.append(shadowAfter);
            }
            if (shadowBefore.length) shadowBefore[0].style.opacity = Math.max(-progress, 0);
            if (shadowAfter.length) shadowAfter[0].style.opacity = Math.max(progress, 0);
          }
          $slideEl
            .transform(`translate3d(${tx}px, ${ty}px, 0px) rotateX(${rotateX}deg) rotateY(${rotateY}deg)`);
        }
      },
      setTransition(duration) {
        const swiper = this;
        const { slides, activeIndex, $wrapperEl } = swiper;
        slides
          .transition(duration)
          .find('.swiper-slide-shadow-top, .swiper-slide-shadow-right, .swiper-slide-shadow-bottom, .swiper-slide-shadow-left')
          .transition(duration);
        if (swiper.params.virtualTranslate && duration !== 0) {
          let eventTriggered = false;
          // eslint-disable-next-line
          slides.eq(activeIndex).transitionEnd(function onTransitionEnd() {
            if (eventTriggered) return;
            if (!swiper || swiper.destroyed) return;
  
            eventTriggered = true;
            swiper.animating = false;
            const triggerEvents = ['webkitTransitionEnd', 'transitionend'];
            for (let i = 0; i < triggerEvents.length; i += 1) {
              $wrapperEl.trigger(triggerEvents[i]);
            }
          });
        }
      }
    }
  };
  let isApiCall=null;
  useEffect(() => {
      //logBatteryInfo();
          init();
          //isApiCall=1;
  },[auth]);
  const init=async()=>{
    const data=await getAuthData();
    if(!data || (data && (!data.baseUrl || data.baseUrl == '')))
        ionRouter.push('/config');
    if(data.data){
      //console.log('auth 55=',auth.password);
      setLogin({
        ...login,
        email:auth.email ?? '',
        password:auth.password ?? '',
      });
    }
    //setAuthData(auth);
  }
  const logBatteryInfo = async () => {
      const info = await getDeviceInfo();
      if(info.platform != 'web'){
          setHeaderMobile([headerMobile].concat('header_mobile'));
      }
  };
  //console.log("count=",count);
  //ldffyrhUjJA6
  const handleLogin = async(e) => {
        e.preventDefault();
        // Handle login logic here
        setLoading({...loading,isOpen:true});
        const api=await callPost('/api/setting/admin/auth/sign-in',login);
        console.log("api=",api);
        if(api && api.error == undefined){
            auth.data=api;
            auth.isAuthenticate=true;
            auth.password=login.password;
            auth.email=login.email;
            //auth.baseUrl=auht.base;
            setAuthData(auth);
            await dispatch(loginAction(api));
            //console.log("login click");    
            //setTimeout(() => {
                ionRouter.push('/home');
                //window.location.reload();
            //}, 1000);
        }
        else{
            presentAlert({
                header: 'Alert',
                subHeader: 'Warning',
                message: api.error,
                buttons: ['OK'],
            });   
        }
        setLoading({...loading,isOpen:false});
        
        
        
  };

  const handleClick= useCallback((name,value) => {
    console.log("log=",name,value);
    if(value != undefined){
      setLogin({
          ...login,
          [name]:value
      });
    }
  }, [login.email,login.password]);

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="danger" className={headerMobile.join(' ')}>
          <IonTitle>Login</IonTitle>
          <IonButtons slot="primary">
              <IonButton routerLink="/config">Config</IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <form onSubmit={handleLogin}>
          <IonRow>
                <IonCol size-sm="8" offset-sm="2" size-xs="12">
                    <IonCard>
                        {/* <IonCardHeader>
                            <IonCardTitle>&nbsp;</IonCardTitle>
                        </IonCardHeader> */}
                        <IonCardContent>
                            <IonRow>
                                <IonCol size-sm="12" size-xs="12">
                                  {/* <IonSlides className='login_sliders' pager={true} options={slideOpts}>
                                      <IonSlide>
                                          <IonVideo src="https://www.w3schools.com/html/mov_bbb.mp4" autoplay={true} controls={true} />
                                      </IonSlide>
                                      <IonSlide>
                                        <h1>Slide 2</h1>
                                      </IonSlide>
                                      <IonSlide>
                                        <h1>Slide 3</h1>
                                      </IonSlide>
                                  </IonSlides> */}
                                </IonCol>
                            </IonRow>
                            <IonRow>
                                <IonCol size-sm="4" offset-sm="4" size-xs="12">
                                    <div>
                                        <IonLabel class="label">User or Email</IonLabel>
                                        <IonInput
                                            type="email"
                                            value={login.email} 
                                            onIonChange={(e)=>handleClick('email',e.detail.value)} required
                                            color="primary"
                                            placeholder="User or Email" 
                                            class="custom"/>
                                    </div>
                                    <div>
                                        <IonLabel class="label">Password</IonLabel>
                                        <IonInput
                                            color="primary"
                                            type="password"
                                            value={login.password} 
                                            onIonChange={e => handleClick('password',e.detail.value)} required 
                                            placeholder="Password" 
                                            class="custom"></IonInput>
                                    </div>
                                    <div>
                                      <IonButton type="submit" expand="block">Login</IonButton>
                                    </div>
                                </IonCol>
                            </IonRow>
                        </IonCardContent>
                    </IonCard>
                </IonCol>
            </IonRow>
            <LoadingIon modal={loading} />
          {/* <IonItem>
            <IonLabel position="floating">Email</IonLabel>
            <IonInput type="email" value={login.email} onIonChange={e => handleClick('email',e.detail.value)} required></IonInput>
          </IonItem>
          <IonItem>
            <IonLabel position="floating">Password</IonLabel>
            <IonInput type="password" value={login.password} onIonChange={e => handleClick('password',e.detail.value)} required></IonInput>
          </IonItem>
          <IonButton type="submit" expand="block">Login</IonButton> */}
        </form>
      </IonContent>
    </IonPage>
  );
};
export default LoginPage;

import { IonHeader, IonTitle, IonToolbar,IonButtons,IonMenuButton } from '@ionic/react';
const MenuHeader=(props)=>{
    const {headers}=props;
    return(
        <IonHeader>
            <IonToolbar>
                <IonButtons slot="start">
                    <IonMenuButton />
                </IonButtons>
                <IonTitle>{headers.title}</IonTitle>
            </IonToolbar>
        </IonHeader>
    )
}
export default MenuHeader;
  import axios from "axios";
  import StorageIonic from "../plugins/StorageIonic";
  import { authKey } from "../actions/types";
    export const API_URL="http://localhost:8000";
    export const callPost=async(api,payload={},status)=>{
      const auth=await (await StorageIonic.init()).auth();
      const endPoint=status != undefined && status != 0 ? api : auth.baseUrl + api;
        return axios
          .post(endPoint, payload,{headers: {
              'Authorization': `Bearer ${auth.data ? auth.data.token : ''}`,
              'Content-Type': 'application/json'
            }}) //withHeader(auth_type)
          .then(response => {
            return response.data;
          })
          .catch(e => {
            console.log("error=",e);
            return returnErrorsRes(e);
          });
    }
    export const callGet=async(api,params={},status)=>{

      //redirectToLogin();
      const auth=await (await StorageIonic.init()).auth();
      var config = {
        'Authorization': `Bearer ${auth.data ? auth.data.token : ''}`,
        'Content-Type': 'application/json'
      }
      const endPoint=status != undefined && status != 0 ? api : auth.baseUrl + api;
        return axios
          .get(endPoint,{params:params,headers: config})
          .then(response => {
            return response.data;
          })
          .catch((e) => {
            console.log("error=",e);
            //console.log("error res=",e.response);
            return returnErrorsRes(e);
            
        });
    }
    export const returnErrorsRes=(e)=>{
      if(e && e.response){
          if(e.response.status == '401')
            redirectToLogin();
          else
            return {error:e.response ? (e.response.data.message ? e.response.data.message:(e.response.data.errors ?? e.response.data)):'not found'};
      }
      else
        return {error:e.message};
    }
    export const redirectToLogin=async()=>{
      //const useAuth=useAuthStore();
      //await useAuth.logout();  
    }

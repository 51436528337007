import { authKey } from "../actions/types";
import { Storage } from '@ionic/storage'
class StorageIonic{
     constructor(){
         this.storage = new Storage();
    }
    async init(){
        this.storageInstance = await this.storage.create();
        return this;
    }
    async setLoginAuth(data){
        const auth=await this.auth();
        console.log("log data=",auth);
        //isAuthenticate
    }
    async auth(){
        const value=await this.storageInstance.get(authKey);
        const data=JSON.parse(value);
        return data;
    }
    async setAuth(data){
        await this.storageInstance.set(authKey, JSON.stringify(data));
    }
    async removeKey(data){
        await this.storageInstance.remove(authKey);
    }
}
export default new StorageIonic();
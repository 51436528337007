import { IonAvatar, IonButton, IonButtons, IonCard, IonCardContent, IonCol, IonIcon, IonLabel, IonRow, IonToolbar } from "@ionic/react";
import './css/support.css';
import { call,logoWhatsapp,chatbox,phonePortrait} from 'ionicons/icons';

import { Share } from '@capacitor/share';
import {getIcon} from "../../plugins/Icons";
import { Browser } from '@capacitor/browser';
import { useEffect, useState } from "react";
import { callGet } from "../../composables/Api";
import LoadingIon from "../shared/LoadingIon";
const SupportComponent=()=>{

    const [list,setList]=useState([]);
    const [loading,setLoading]=useState({
        isOpen:false,
        message:'Loading...',
    },);
    useEffect(() => {
        get();
    },[])
    const get=async()=>{
 
        setLoading({...loading,isOpen:true});
        const url='https://office.cursorltd.com/api/support/index';
        const api=await callGet(url,{},1);
        if(api && api.error == undefined){
          setList(api.list);
        }
        setLoading({...loading,isOpen:false});

    }
    const handleSupport=async(app)=>{
        // await Share.share({
        //     title: 'See cool stuff',
        //     text: 'Really awesome thing you need to see right meow',
        //     url: 'http://ionicframework.com/',
        //     dialogTitle: 'Share with buddies',
        // });
        await Browser.open({ url: `tel:${app.mobile}` });
    }
    const handleBrowse=async(app)=>{
        await Browser.open({ url: app.link });
        //await Browser.open({ url: app.link+app.params });
    }
    const contentCard=list.map(row =>{
        return(
            <IonCol size-sm="4" size-xs="6">
                <IonCard>
                    <IonCardContent>
                        <div expand="block" className="support_card">
                                <IonAvatar>
                                    <img 
                                        alt="Silhouette of a person's head" 
                                        src="https://ionicframework.com/docs/img/demos/avatar.svg" />
                                </IonAvatar>
                                <h3 className="support_title">{row.name}</h3>
                                <div>
                                    <IonButtons slot="primary">
                                        {
                                             row.socialAcc.map(app => (
                                                <IonButton onClick={()=>{handleBrowse(app)}}>
                                                    <IonIcon style={{color:'#fff'}} 
                                                        size="small" 
                                                        slot="icon-only" 
                                                        icon={getIcon(app.icon)}></IonIcon>
                                                </IonButton>
                                             ))
                                            //{
                                            //     const Content=
                                            //         app.name == 'phone' ? (
                                            //             <a href={`tel:${app.mobile}`}>
                                            //                 <IonIcon style={{color:'#fff'}} size="small" slot="icon-only" icon={getIcon(app.icon)}></IonIcon>
                                            //             </a>            
                                            //         ) : (
                                            //         <IonButton onClick={()=>{handleBrowse(app)}}>
                                            //             <IonIcon style={{color:'#fff'}} size="small" slot="icon-only" icon={getIcon(app.icon)}></IonIcon>
                                            //         </IonButton>
                                            //     )
                                            //     return(
                                            //         Content                  
                                            //     )
                                            // })
                                        }
                                        {/* <IonButton onClick={()=>{handleBrowse(row)}}>
                                            <IonIcon  size="small" slot="icon-only" icon={logoWhatsapp}></IonIcon>
                                        </IonButton>
                                        <IonButton  onClick={()=>{handleBrowse(row)}}>
                                            <IonIcon  size="small" slot="icon-only" icon={chatbox}></IonIcon>
                                        </IonButton> */}
                                    </IonButtons>
                                    {/* <IonIcon icon={logoWhatsapp}></IonIcon> &nbsp;
                                    <IonLabel style={{fontSize:'12px',fontWeight:'bold'}}>                                  
                                        <a href="https://wa.me/8801817119173?text=Hello%20How%20are%20you">
                                            +8801817119173
                                        </a>       
                                    </IonLabel> */}
                                </div>
                            </div>
                    </IonCardContent>
                </IonCard>
            </IonCol>
        )
    })


    return(
        <>
            <IonRow>
                {contentCard}
            </IonRow>
            <LoadingIon modal={loading} />
        </>
        
    )
}
export default SupportComponent;
import { chatbox, logoWhatsapp, phonePortrait,mail } from "ionicons/icons";

export const icons=[
    {
        name:'logoWhatsapp',
        icon:logoWhatsapp,
    },
    {
        name:'chatbox',
        icon:chatbox,
    },
    {
        name:'phonePortrait',
        icon:phonePortrait,
    },
    {
        name:'mail',
        icon:mail,
    },
]
export  const getIcon=(name)=>{
    const row=icons.find((row) => (row.name == name));
    return row.icon;
}
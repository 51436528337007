import { Redirect, Route, useLocation,Router, Switch } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,IonToolbar,
  IonMenu,IonHeader,IonTitle,IonContent,
  IonList,IonItem,
  setupIonicReact
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { home,list,settings,help } from 'ionicons/icons';
import HomeTab from './pages/HomeTab';
import ReportAttendanceTab from './pages/ReportAttendanceTab';
import Tab3 from './pages/Tab3';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './theme/tabCss.css';
import { Provider, useDispatch, useSelector } from 'react-redux';
import store from './store';
import LoginPage from './pages/auth/LoginPage';
import PrivateRoute from './components/auth/PrivateRoute';
import StorageIonic from './plugins/StorageIonic';
import { useEffect, useState } from 'react';
import { getAuthData, initAction, removeAuth } from './actions/authActions';
import Routes from './routes';
import ConfigPage from './pages/auth/ConfigPage';
import { StatusBar, Style } from '@capacitor/status-bar';
import { Device } from '@capacitor/device';

//ionic capacitor sync android
//ionic capacitor copy android

setupIonicReact();
const App=() => {
    //const [auth,setAuth]=useState(null);
    //const location = useLocation();
    //const currentUrl = location.pathname;
    //console.log("location=",location);
    //console.log("match=",match);
    useEffect(() => {
      authHandle();
    },[])
    const authHandle=async()=>{

      const info = await Device.getInfo();
      if(info.platform != 'web'){
          // StatusBar.setOverlaysWebView({
          //   overlay: true
          // });
          StatusBar.setBackgroundColor({color:"#E92954"});
      }
  
      //await StatusBar.setBackgroundColor('')
      //removeAuth();
      //setAuth(await getAuthData());
    }
    //console.log("auth=",auth);

    return(
      <Provider store={store}>
        <IonApp>
            <IonReactRouter>
              <WrappedApp></WrappedApp>
            </IonReactRouter>
        </IonApp>
      </Provider>
    )
}
export const WrappedApp=()=>{

  const dispatch=useDispatch();
  const auth=useSelector(state => state.auth);
  const location = useLocation();
  useEffect(() => {
    init();
  },[])

  const init=async()=>{
    dispatch(await initAction());
  }
  console.log("init dispatch=",auth);

  
  return (
        <>
          <IonMenu contentId="main">
            <IonHeader>
              <IonToolbar>
                <IonTitle>Menu</IonTitle>
              </IonToolbar>
            </IonHeader>
            <IonContent>
              <IonList>
                <IonItem routerLink="/home">
                  <IonLabel>Home</IonLabel>
                </IonItem>
                <IonItem routerLink="/report-attendance">
                  <IonLabel>Tab 2</IonLabel>
                </IonItem>
              </IonList>
            </IonContent>
          </IonMenu>
          <IonRouterOutlet>
                <Route exact path="/login">
                    <LoginPage />
                </Route>
                <Route exact path="/config">
                    <ConfigPage />
                </Route>
                <Routes />
          </IonRouterOutlet>
                {
                    auth && auth.isAuthenticate && location && (location.pathname != '/login' && location.pathname != '/config') ? (
                      <>
                        <IonTabs>
                            <IonRouterOutlet id="main">
                                <Routes />
                            </IonRouterOutlet>
                            <IonTabBar slot="bottom" className="custom-tab-bar">
                                <IonTabButton className='tab-button' tab="home" href="/home">
                                  <div className='tab_icon_holder'>
                                    <IonIcon aria-hidden="true" icon={home} />
                                  </div>
                                  <IonLabel className='tab_label'>Home</IonLabel>
                                </IonTabButton>
                                <IonTabButton className='tab-button' tab="tab3" href="/tab3">
                                  <div className='tab_icon_holder'>
                                    <IonIcon aria-hidden="true" icon={settings} />
                                  </div>
                                  <IonLabel className='tab_label'>Function</IonLabel>
                                </IonTabButton>
                                <IonTabButton className='tab-button' tab="device" href="/device">
                                  <div className='tab_icon_holder'>
                                    <IonIcon aria-hidden="true" icon={list} />
                                  </div>
                                  <IonLabel className='tab_label'>Device</IonLabel>
                                </IonTabButton>
                                {/* <IonTabButton className='tab-button' tab="report" href="/report-attendance">
                                  <div className='tab_icon_holder'>
                                    <IonIcon aria-hidden="true" icon={list} />
                                  </div>
                                  <IonLabel className='tab_label'>Report</IonLabel>
                                </IonTabButton> */}
                                <IonTabButton className='tab-button' tab="support" href="/support">
                                  <div className='tab_icon_holder'>
                                    <IonIcon aria-hidden="true" icon={help} />
                                  </div>
                                  <IonLabel className='tab_label'>Support</IonLabel>
                                </IonTabButton>
                                
                            </IonTabBar>
                        </IonTabs>
                      </>
                    ) : null
                }
          
      </>
  );
}

export default App;

import { IonButton, IonCol, IonGrid, IonIcon, IonImg, IonRow, IonTitle, useIonAlert } from "@ionic/react";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { callPost } from "../../composables/Api";
import { getDeviceInfo } from "../../composables/DeviceApi";
import { getImageBase64Data, photoGalleryApi } from "../../composables/ImageApi";
import { getCurrentLocation } from "../../composables/MapApi";
import LoadingIon from "../shared/LoadingIon";
import { image } from 'ionicons/icons';
import { Geolocation } from '@capacitor/geolocation';


const HomeComponent=()=>{
    const initialState={
        photo:null,
        loading:{
            isOpen:false,
            message:'Loading...',
        },
    };
    const [presentAlert] = useIonAlert();
    const auth=useSelector(state => state.auth);
    const [coordinates,setCoordinates]=useState(null);
    const [iframeData,setIframeData]=useState(null);
    const [distanceInMetter,setDistanceInMetter]=useState(0);
    const [photo,setPhoto]=useState(null);
    const [loading,setLoading]=useState(initialState.loading);

    useEffect(() => {
        //fetch();
        locationUpdate();
    },[]);
    useEffect(() => {
        if(coordinates)
            fetch();
    },[coordinates]);

    const locationUpdate=()=>{
        const watchId = Geolocation.watchPosition({
            enableHighAccuracy: true
          }, (position) => {
            console.log('Current location:', position.coords.latitude, position.coords.longitude);
            setCoordinates(position);
        });
    }

    const fetch=async()=>{
        //const location=await getCurrentLocation();
        //console.log("loc=",location);
        const iframe=`https://maps.google.com/maps?q=${coordinates.coords.latitude},${coordinates.coords.longitude}&hl=es;z=18&amp;output=embed`;
        console.log("link=",iframe);
        //setCoordinates(location);
        setIframeData(iframe);
    }
    const handleReset=()=>{

    }
    const takePicture = async () => {
        const resPhoto = await photoGalleryApi();
        setPhoto(resPhoto);
    };
    const handleResetImage=()=>{
        setPhoto(null);
    }
    const handleSubmit=async()=>{

        if(!coordinates){
            presentAlert({
                header: 'Alert',
                subHeader: 'Warning',
                message: "Location is empty...",
                buttons: ['OK'],
            });
        }
        else{

            setLoading({...loading,isOpen:true});
            let convBase64Image=null;
            if(photo)
            convBase64Image=await getImageBase64Data(photo);
            //const coord = await getCurrentLocation();
            const device_info = await getDeviceInfo();
            //https://www.google.com/maps?q=24.4764342,39.6140138&z=17&hl=en
            const postData={
                lat:coordinates.coords.latitude,
                lng:coordinates.coords.longitude,
                deviceInfo:device_info,
                imageData: convBase64Image
            }
            const api=await callPost('/api/uploadAppAttendance',postData);
            console.log("photos=",api);
            if(api && api.error == undefined){
            setDistanceInMetter(api.dis);
                presentAlert({
                    header: 'Alert',
                    subHeader: 'Success',
                    message: "Attendance has taken,thank you.",
                    buttons: ['OK'],
                });
            }
            else{
                presentAlert({
                    header: 'Alert',
                    subHeader: 'Warning',
                    message: api.error,
                    buttons: ['OK'],
                });
            }
            setLoading({...loading,isOpen:false});
        }
      }
    return(
        <IonGrid>
            <IonRow>
                <IonCol size-sm="6" offset-sm="3" size-xs="12">
                    <IonTitle className="mn" size="large">
                        Distance In Metter: {distanceInMetter}
                    </IonTitle>
                </IonCol>
                <IonCol size-sm="6" offset-sm="3" size-xs="12">
                    <p className="mn">
                        Lat,Lng : {coordinates ? `${coordinates.coords.latitude},${coordinates.coords.longitude}`:''}
                    </p>
                </IonCol>
                <IonCol size-sm="6" offset-sm="3" size-xs="12">
                    <IonTitle className="mn" size="large">
                        Photo  
                    </IonTitle>
                </IonCol>
                <IonCol size-sm="6" offset-sm="3" size-xs="12">          
                    <div
                        className="ion-justify-content-center location_img_parent">
                            <img 
                                onClick={takePicture}
                                className="location_img"
                                src={photo ? photo.webPath : '/photo.jpg'} 
                                alt="Image description" 
                            />
                            
                        {/* <IonImg 
                            style={{maxHeight:'120px',minHeight:'120px',overflow:'hidden'}} 
                            src={photo ? photo.webPath : ''}></IonImg> */}
                    </div>
                </IonCol>
                <IonCol size-sm="6" offset-sm="3" size-xs="12">
                    <IonTitle className="mn" size="large">
                        Location  
                    </IonTitle>
                </IonCol>
                <IonCol size-sm="6" offset-sm="3" size-xs="12">
                    {
                        iframeData ? (
                            <div
                                dangerouslySetInnerHTML={{ __html: `<iframe
                                    width="100%" 
                                    height="200"  
                                    frameborder="0" 
                                    style={{border:0}} 
                                    allowfullscreen="" 
                                    aria-hidden="false" 
                                    tabindex="0"
                                    src='${iframeData}'/> }}
                                />` }}
                            />

                            
                        ) : (
                            <IonTitle size="large">
                                <h1>Loading....</h1>  
                            </IonTitle>
                        )
                    }        
                                       
                        
                </IonCol>
                <IonCol size-sm="6" offset-sm="3" size-xs="12">
                    <IonButton onClick={takePicture} size="small">Take Photo</IonButton>
                    <IonButton onClick={handleResetImage} size="small" color="danger">Reset Image</IonButton>
                    <IonButton onClick={handleSubmit} size="small" color="secondary">In/Out</IonButton>
                    <IonButton onClick={fetch} size="small" color="success">Refresh</IonButton>
                </IonCol>
            </IonRow>
            <LoadingIon modal={loading} />
        </IonGrid>
    )
}
export default HomeComponent;
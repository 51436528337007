import { Camera, CameraResultType, CameraSource } from "@capacitor/camera";

export const photoGalleryApi=async()=>{
    const photo = await Camera.getPhoto({
      resultType: CameraResultType.Uri,
      source: CameraSource.Camera,
      quality: 100,
    });
    return photo;
}
export const getImageBase64Data=async(photo)=>{
  const response = await fetch(photo ? photo.webPath : null);
  const blob = await response.blob();
  const base64Data = await convertBlobToBase64(blob);
  return base64Data;
}
export const convertBlobToBase64 = (blob) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onerror = reject;
    reader.onload = () => {
      resolve(reader.result);
    };
    reader.readAsDataURL(blob);
  });